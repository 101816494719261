<template>
  <TModal
    :title="type == 'lading_bill' ? 'Select lading bill' : 'Select order'"
    :show="show"
    @update:show="$emit('update:show', $event)"
    size="xl"
  >
    <template #actions>
      <div />
    </template>
    <TTableAdvance
      :items="list"
      :fields="fieldC"
      :store="store"
      @click-clear-filter="clearFilter"
      resource=""
      reloadable
      class="table-custom"
      @click-reload="fetch"
    >
      <template #_="{ item }">
        <td>
          <TButtonAdd @click="select(item)" />
        </td>
      </template>
      <template #lading_bill_id="{ item }">
        <td>
          <TLink
            :to="`${lodash.getReferenceLink('lading_bill',item.lading_bill_id )}&tab=1`"
            :content="item.lading_bill_id.toString()"
            class="text-nowrap"
          />
        </td>
      </template>
      <template #order_id="{ item }">
        <td>
          <TLink
            v-if="item.order"
            :to="`/sale/${item.order.type_id}/${item.order_id}`"
            :content="item.order_id"
            class="text-nowrap"
          />
        </td>
      </template>
      <template #customer_id="{ item }">
        <td>
          <TLink
            class="text-truncate-1"
            v-if="item.lading_bill"
            :content="
              item.lading_bill.customer
                ? item.lading_bill.customer.name
                : item.lading_bill.customer_id
            "
          />
          <TLink
            class="text-truncate-1"
            v-if="item.order"
            :content="
              item.order.customer
                ? item.order.customer.name
                : item.order.customer_id
            "
          />
        </td>
      </template>

      <template #currency_id="{ item }">
        <td>
          <SMessageCurrency :id="item.currency_id" />
        </td>
      </template>

      <template #amount="{ item }">
        <td>
          <TMessageMoney :amount="item.amount" :currency="item.currency_id" />
        </td>
      </template>

      <template #unsolved="{ item }">
        <td>
          <TMessageMoney :amount="item.unsolved" :currency="item.currency_id" />
        </td>
      </template>

      <template #paid="{ item }">
        <td>
          <TMessageMoney :amount="item.paid" :currency="item.currency_id" />
        </td>
      </template>

      <template #note="{ item }">
        <td>
          <TMessage :content="item.note" italic noTranslate />
        </td>
      </template>

      <template #created_at="{ item }">
        <td>
          <TMessageDateTime :content="item.created_at" small />
        </td>
      </template>

      <template #lading_bill_id-filter>
        <TInputText
          placeholder="Id"
          :value.sync="filter.lading_bill_id"
          @update:value="filterChange"
        />
      </template>
      <template #order_id-filter>
        <TInputText
          placeholder="Order Id"
          :value.sync="filter.order_id"
          @update:value="filterChange"
        />
      </template>
      <template #customer_id-filter>
        <SSelectCustomer
          placeholder="Customer Id"
          :value.sync="
            filter[
              type == 'lading_bill'
                ? 'ladingBill.customer_id'
                : 'order.customer_id'
            ]
          "
          @change="filterChange"
          prependAll
        />
      </template>
      <template #currency_id-filter>
        <SSelectCurrency
          placeholder="Currency Id"
          :value.sync="filter['currency_id']"
          @change="filterChange"
        />
      </template>
      <template #created_at-filter>
        <TInputDateTimeRange
          :value.sync="dateRange"
          @update:value="setCreatedAtFilter"
        />
      </template>
    </TTableAdvance>
  </TModal>
</template>

<script>
import organizationStore from "@/core/services/tomoni/organization.local";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "lading_bill",
      validator: function (value) {
        return ["lading_bill", "order"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      dateRange: {
        start: null,
        end: null,
      },
      filter: {},
      filter_between: {},
      fieldLadingbill: {
        key: "lading_bill_id",
        label: "Lading bill",
        _style: "width: 100px; min-width: 100px",
      },

      fieldOrder: {
        key: "order_id",
        label: "Order Id",
        _style: "width: 100px; min-width: 100px",
      },
      fieldDefault: {
        key: "_",
        label: "",
      },
      fields: [
        {
          key: "customer_id",
          label: "Customer",
          _classes: "",
          _style: "width: 170px; min-width: 170px",
        },
        {
          key: "currency_id",
          label: "Currency",
          _classes: "",
          _style: "width: 140px; min-width: 140px",
        },
        {
          key: "amount",
          label: "Compensation amount",
          sorter: true,
        },
        {
          key: "paid",
          label: "Compensated",
          sorter: true,
        },
        {
          key: "unsolved",
          label: "Uncompensated",
          sorter: true,
        },
        {
          key: "note",
          label: "Note",
          _style: "width: 170px; min-width: 170px",
        },
        {
          key: "created_at",
          label: "Created at",
          _style: "width: 220px; min-width: 220px",
        },
      ],
    };
  },
  watch: {
    show(isShow) {
      if (isShow) {
        this.filterChange();
      }
    },
  },
  computed: {
    list() {
      return this.$store.getters[this.store + ".list"];
    },
    store() {
      if (this.type == "lading_bill")
        return "warehouse.accouting_lading_bill_compensations";
      return "order.sale_compensations";
    },
    organization() {
      return organizationStore.get();
    },
    fieldC() {
      if (this.type == "lading_bill") {
        return [this.fieldDefault, this.fieldLadingbill, ...this.fields];
      }
      return [this.fieldDefault, this.fieldOrder, ...this.fields];
    },
  },
  methods: {
    select(compensation) {
      this.$emit("select", compensation);
      this.$emit("update:show", false);
    },
    fetch() {
      this.$store.dispatch(this.store + ".fetch");
    },
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterBetween = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filter,
        ...filterBetween,
      });
      this.$store.dispatch(this.store + ".apply-query", {
        "filter[organization_id]": this.organization,
        ...this.default_filter,
        ...filterFields,
      });
    },
    setCreatedAtFilter({ start, end }) {
      this.filter_between["created_at"] = `${start},${end}`;
      this.filterChange();
    },
    clearFilter() {
      this.filter_between = {};
      this.filter = {};
      this.dateRange = {
        start: null,
        end: null,
      };
      this.filterChange();
    },
  },
};
</script>

<style scoped>
* >>> .table-custom .table-responsive {
  min-height: 400px;
}
</style>
