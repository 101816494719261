<template>
  <div>
    <CCard>
      <CCardHeader class="d-flex flex-wrap justify-content-start">
        <div class="d-flex align-items-center">
          <TMessage content="Compensation" bold />
          <TSpinner :loading="creating" />
        </div>
        <div class="ml-auto">
          <TButtonClear
            variant="outline"
            color="primary"
            class="mr-1"
            @click="clearInput"
          />
          <TButton
            content="Create"
            icon="cis-plus"
            @click="create"
            :options="{ disabled: creating }"
          />
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="7">
            <CRow class="row-gap-3">
              <CCol md="6">
                <div
                  style="position: relative"
                  @click="showModalCompensation = true"
                >
                  <div
                    style="
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      z-index: 2;
                    "
                  />
                  <TInputText
                    label="Order"
                    :value="temp.order_id"
                    placeholder="Select order"
                  />
                </div>
              </CCol>

              <CCol md="6">
                <TInputText label="Customer" :value="customer" disabled />
              </CCol>
            </CRow>

            <CRow class="row-gap-3 mt-3">
              <CCol md="3">
                <TInputDateTime
                  label="Voucher date"
                  :value.sync="input.voucher_date"
                />
              </CCol>

              <CCol md="3">
                <TInputText
                  label="Currency"
                  :value="lodash.symbolCurrency(input.currency_id)"
                  disabled
                />
              </CCol>

              <CCol md="6">
                <TInputMoney
                  label="Amount"
                  :value.sync="input.amount"
                  :currency="input.currency_id"
                />
              </CCol>

              <CCol md="12">
                <TInputTextarea
                  label="Description"
                  placeholder="Description"
                  :value.sync="input.description"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol md="5">
            <TInputFile label="Receipts" class="mb-3" :value.sync="receipts" />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <SelectCompensationModal
      :show.sync="showModalCompensation"
      @select="onChangeCompensation"
      type="order"
    />
  </div>
</template>

<script>
import SelectCompensationModal from "../components/SelectCompensationModal.vue";
export default {
  components: { SelectCompensationModal },
  data() {
    return {
      temp: {},
      input: {},
      receipts: [],
      showModalCompensation: false,
    };
  },
  computed: {
    creating() {
      return this.$store.getters[
        "accounting.transactions_compensation.creating"
      ];
    },
    customer() {
      if (this.temp.order) {
        return this.temp?.order?.customer
          ? this.temp.order.customer.name
          : this.temp.order.customer_id;
      } else return "";
    },
  },
  methods: {
    create() {
      this.$store
        .dispatch(
          "accounting.transactions_compensation.create",
          this.getDataCreate()
        )
        .then(() => {
          this.clearInput();
        });
    },
    getDataCreate() {
      let params = {
        ...this.input,
        transactionable_type: "user",
        "receipts[0][receiptable_id]": this.temp.order_id,
        "receipts[0][receiptable_type]": "order",
      };

      this.receipts.forEach((receipt, index) => {
        params[`receipts[${index + 1}][receiptable_type]`] = "file";
        params[`receipts[${index + 1}][file]`] = receipt.file;
      });

      return params;
    },
    clearInput() {
      this.temp = {};
      this.input = {};
      this.receipts = [];
    },
    onChangeCompensation(comp) {
      this.temp = comp;
      this.$set(this.input, "transactionable_id", comp.order.customer_id);
      this.$set(this.input, "currency_id", comp.currency_id);
      this.$set(this.input, "amount", comp.unsolved);
    },
  },
};
</script>
